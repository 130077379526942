<template>
  <div class="contact" id="contact" :class="getCurrentLangs.lang" data-view>
    <Header/>
    <scrollAnalytics></scrollAnalytics>

    <div class="contact__content" v-show="!isFormSent">
      <div class="contact__top">
        <div
          class="contact__back cursor-hover"
          @click="$router.go(-1)"
        >
          <svg width="47" height="57" viewBox="8 0 47 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.5391 19.3223L14.8516 30.01L25.5487 40.7071" stroke="black" stroke-linecap="round"/>
            <path d="M15.3012 29.973L38.2422 29.9732" stroke="black" stroke-linecap="round"/>
          </svg>
          {{ $t('contact.back') }}
        </div>
        <p class="contact__required">
          {{ $t('contact.required') }}
        </p>
      </div>
      <div class="contact__main">
        <div class="contact__info">
          <h2 class="contact__title desk">
            <span class="text-wrapper">
              <span class="letters">
                {{ `${$t('contact.title[0]') + '&nbsp;'}` }}
              </span>
            </span>
            <span class="text-wrapper">
              <span class="letters">
                {{ $t('contact.title[1]') }}
              </span>
            </span>
          </h2>
          <h2 class="contact__title mob">
            <span class="text-wrapper">
              <span class="letters">
                {{ `${$t('contact.title[0]') + '&nbsp;' + $t('contact.title[1]')}` }}
              </span>
            </span>
          </h2>

          <div class="contact__address desk-tablet">
            <p class="contact__address-title">
              {{ $t('contact.address.title') }}
            </p>
            <p class="contact__address-text cursor-hover">
              {{ $t('contact.address.text[0]') }}<br>
              <span v-html="$t('contact.address.text[1]')"></span><br>
              {{ $t('contact.address.text[2]') }}
            </p>
          </div>
        </div>
        <form
          action="GET"
          class="contact__form"
          @submit.prevent="handleSubmit"
          novalidate
        >
          <div class="contact__field cursor-hover">
            <label for="name" class="contact__label">
              {{ $t('contact.name.label') }}<sup>*</sup>
            </label>
            <input
              class="contact__input"
              :class="{ error: errors.name }"
              type="text"
              name="name"
              v-model.trim="name"
              @input="handleNameValidation"
              :placeholder="$t('contact.name.placeholder')"
            />
            <span class="contact__max first" v-if="name">
              {{ name.replace(/\s/g, '').length }}/50
            </span>
            <p class="contact__error" v-if="errors.name">
              {{ errors.name }}
            </p>
          </div>
          <div class="contact__field cursor-hover">
            <label for="magic" class="contact__label">
              {{ $t('contact.magic.label') }}
            </label>
            <div class="contact__select" @click="isMagicListHidden = !isMagicListHidden; isWorkListHidden = true;">
              <div class="contact__select-shown" :class="{ active: !isMagicListHidden }">
                <p class="contact__select-placeholder" :class="{ active: magic }">
                  {{ magic || $t('contact.magic.placeholder') }}
                </p>
                <svg width="26" height="14" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L13 13L25 1" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div class="contact__select-list" :class="{ hidden: isMagicListHidden }">
                <div
                  class="contact__select-item cursor-hover"
                  :class="{ active: magic === item }"
                  v-for="item of $t('contact.magic.items')"
                  :key="item"
                  @click.stop="selectMagic(item)"
                >
                  <p>
                    {{ item }}
                  </p>
                  <svg v-if="magic === item" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.998 1L7.52044 13.4649L1.22681 7.52094" stroke="black" stroke-width="2" stroke-linecap="round"/>
                  </svg>
                </div>
              </div>
            </div>
            <p class="contact__error" v-if="errors.magic">
              {{ errors.magic }}
            </p>
          </div>
          <div class="contact__field cursor-hover">
            <label for="magic" class="contact__label">
              {{ $t('contact.work.label') }}
            </label>
            <div class="contact__double-fields">
              <div>
                <div class="contact__select" @click="isWorkListHidden = !isWorkListHidden; isMagicListHidden = true;">
                  <div class="contact__select-shown" :class="{ active: !isWorkListHidden }">
                    <p class="contact__select-placeholder" :class="{ active: work }">
                      {{ work || $t('contact.work.placeholder') }}
                    </p>
                    <svg width="26" height="14" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1L13 13L25 1" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div class="contact__select-list" :class="{ hidden: isWorkListHidden }">
                    <div
                      class="contact__select-item cursor-hover"
                      :class="{ active: work === item }"
                      v-for="item of $t('contact.work.items')"
                      :key="item"
                      @click.stop="selectWork(item)"
                    >
                      <p>
                        {{ item }}
                      </p>
                      <svg v-if="work === item" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.998 1L7.52044 13.4649L1.22681 7.52094" stroke="black" stroke-width="2" stroke-linecap="round"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <p class="contact__error" v-if="errors.work">
                  {{ errors.work }}
                </p>
              </div>
              <div class="company-name">
                <input
                  class="contact__input"
                  type="text"
                  v-model="company"
                  @input="handleCompanyValidation"
                  :placeholder="$t('contact.company.placeholder')"
                />
                <span class="contact__max second" v-if="company">
                  {{ company.replace(/\s/g, '').length }}/20
                </span>
                <p class="contact__error" v-if="errors.company">
                  {{ errors.company }}
                </p>
              </div>
            </div>
          </div>
          <div class="contact__field cursor-hover">
            <label for="email" class="contact__label">
              {{ $t('contact.email.label') }}<sup>*</sup>
            </label>
            <input
              class="contact__input"
              :class="{ error: errors.email }"
              type="email"
              name="email"
              v-model.trim="email"
              @input="handleEmailValidation"
              :placeholder="$t('contact.email.placeholder')"
            />
            <p class="contact__error" v-if="errors.email">
              {{ errors.email }}
            </p>
          </div>
          <div class="contact__field contact__field--phone cursor-hover">
            <div class="contact__double-fields contact__double-fields--phone">
              <div class="contact__field">
                <label for="phone" class="contact__label">
                  {{ $t('contact.phone.label') }}<sup>*</sup>
                </label>
                <TheMask
                  class="contact__input"
                  :class="{ error: errors.phone }"
                  name="phone"
                  v-model="phone"
                  :placeholder="$t('contact.phone.placeholder')"
                  @input="handleError('phone', null)"
                  type="tel"
                  mask="+## (###) ### ## ##"
                />
                <p class="contact__error" v-if="errors.phone">
                  {{ $t('contact.phone.error.empty') }}
                </p>
              </div>
            </div>
          </div>
          <div class="contact__field cursor-hover">
            <label class="contact__checkbox cursor-hover" :class="{ error: errors.agree }">
              <input type="checkbox" class="contact__checkbox-input" @change="handleAgree">
              <div class="contact__checkbox-custom" :class="{ active: isAgree }">
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 1.70801L5.25237 10.1221L1.00403 6.10978" stroke="white" stroke-width="2" stroke-linecap="round"/>
                </svg>
              </div>
              <p>
                {{ $t('contact.agree[0]') }}
                <span>
                  {{ $t('contact.agree[1]') }}
                </span>
              </p>
              <sup>*</sup>
            </label>
          </div>
          <button
            class="contact__button biggest-cursor-hover"
            :class="{
              disabled:
                !this.name
                  || !this.email
                  || !this.phone
                  || this.errors.name
                  || this.errors.email
                  || this.errors.phone
                  || this.errors.agree
            }"
            type="submit"
          >
            {{ $t('contact.send') }}
          </button>
        </form>

        <div class="contact__address mob-second">
          <p class="contact__address-text cursor-hover">
            {{ $t('contact.address.text[0]') }}<br>
            <i v-html="$t('contact.address.text[1]')"></i><br>
            {{ $t('contact.address.text[2]') }}
          </p>
        </div>
      </div>
    </div>

    <div class="contact__result" v-show="isFormSent">
      <svg width="396" height="396" viewBox="0 0 396 396" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M158.4 59.8374C159.346 53.0912 162.915 46.9906 168.332 42.8597C173.749 38.7289 180.577 36.9012 187.333 37.7735C194.089 38.6457 200.229 42.1474 204.419 47.5186C208.61 52.8897 210.512 59.6968 209.715 66.4622M310.546 206.663C312.357 210.334 313.195 214.408 312.982 218.496C312.768 222.583 311.509 226.548 309.325 230.01C307.054 233.942 303.788 237.207 299.856 239.476C295.923 241.746 291.463 242.941 286.922 242.941C282.382 242.941 277.921 241.746 273.989 239.476C270.056 237.207 266.79 233.942 264.519 230.01M257.4 48.1637C261.384 42.6691 267.369 38.9604 274.063 37.8373C280.756 36.7141 287.623 38.2664 293.183 42.1595C298.743 46.0527 302.551 51.9748 303.785 58.6491C305.019 65.3234 303.58 72.2153 299.78 77.8389M319.605 296.241C313.199 313.575 301.896 328.675 287.07 339.706C272.244 350.737 254.533 357.225 236.09 358.38C217.22 360.252 198.204 356.756 181.234 348.294C164.264 339.833 150.028 326.75 140.167 310.553C130.307 294.355 125.221 275.702 125.497 256.741C125.773 237.78 131.399 219.283 141.726 203.379"
          stroke="#354F57" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M245.355 159.39C259.209 161.479 272.359 166.863 283.7 175.089C295.041 183.315 304.242 194.143 310.53 206.663" stroke="#354F57" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path
          d="M319.613 296.241C320.94 291.899 321.1 287.284 320.078 282.859C319.057 278.435 316.888 274.358 313.792 271.037C310.695 267.717 306.779 265.269 302.437 263.941C298.095 262.613 293.48 262.452 289.055 263.472C284.534 264.672 280.393 267.004 277.024 270.249C273.654 273.494 271.168 277.545 269.8 282.018C260.486 301.983 239.877 309.4 218.27 305.102C207.768 303.016 198.269 297.466 191.296 289.34C184.323 281.214 180.28 270.983 179.813 260.285C179.346 249.588 182.483 239.044 188.721 230.341C194.96 221.639 203.939 215.282 214.22 212.289C235.373 206.135 253.498 210.936 264.512 230.01M257.4 48.1636L141.735 203.371M245.355 159.39L299.772 77.8388M127.256 239.423L158.4 59.8373M200.681 124.27L209.715 66.4538M77.5254 155.991C76.0968 152.887 75.2974 149.53 75.1733 146.115C75.0492 142.7 75.6029 139.294 76.8025 136.094C78.002 132.894 79.8235 129.964 82.162 127.472C84.5004 124.98 87.3093 122.976 90.4266 121.575C93.5438 120.175 96.9076 119.406 100.324 119.313C103.74 119.22 107.14 119.805 110.329 121.033C113.518 122.262 116.432 124.11 118.903 126.471C121.373 128.832 123.352 131.659 124.724 134.789M130.433 288.833L77.5336 155.999M139.343 169.752L124.724 134.78"
          stroke="#354F57" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <div>
        <h2 class="contact__title thanks">
          <span class="text-wrapper">
            <span class="letters">
              {{ $t('contact.result.title') }}
            </span>
          </span>
        </h2>

        <p class="contact__text">
          {{ $t('contact.result.text[0]') }}
          <br>
          {{ $t('contact.result.text[1]') }}
        </p>

        <router-link to="/" class="contact__button-res cursor-hover">
          <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6953 1.00781L1.00787 11.6955L11.7049 22.3926" stroke-linecap="round"/>
            <path d="M1.4555 11.6585L24.3965 11.6588" stroke-linecap="round"/>
          </svg>
          {{ $t('contact.result.button') }}
        </router-link>
      </div>

      <img class="contact__gradients" src="@/assets/img/main-page/Ellipse-1.svg">
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import {mapGetters, mapMutations} from 'vuex';
import {TheMask} from 'vue-the-mask';
import anime from "animejs";

export default {
  name: 'contact',
  components: {
    Header,
    TheMask,
  },
  data() {
    return {
      name: null,
      magic: null,
      work: null,
      company: null,
      email: null,
      phone: null,
      isAgree: false,

      errors: {
        name: null,
        magic: null,
        work: null,
        company: null,
        email: null,
        phone: null,
        agree: null,
      },

      isFormSent: false,
      isWorkListHidden: true,
      isMagicListHidden: true,
    };
  },
  // watch: {
  //   name(newVal) {
  //     let re = /[^\u{203C}\u{2049}\u{20E3}\u{2122}\u{2139}\u{2194}-\u{2199}\u{21A9}-\u{21AA}\u{231A}-\u{231B}\u{23E9}-\u{23EC}\u{23F0}\u{23F3}\u{24C2}\u{25AA}-\u{25AB}\u{25B6}\u{25C0}\u{25FB}-\u{25FE}\u{2600}-\u{2601}\u{260E}\u{2611}\u{2614}-\u{2615}\u{261D}\u{263A}\u{2648}-\u{2653}\u{2660}\u{2663}\u{2665}-\u{2666}\u{2668}\u{267B}\u{267F}\u{2693}\u{26A0}-\u{26A1}\u{26AA}-\u{26AB}\u{26BD}-\u{26BE}\u{26C4}-\u{26C5}\u{26CE}\u{26D4}\u{26EA}\u{26F2}-\u{26F3}\u{26F5}\u{26FA}\u{26FD}\u{2702}\u{2705}\u{2708}-\u{270C}\u{270F}\u{2712}\u{2714}\u{2716}\u{2728}\u{2733}-\u{2734}\u{2744}\u{2747}\u{274C}\u{274E}\u{2753}-\u{2755}\u{2757}\u{2764}\u{2795}-\u{2797}\u{27A1}\u{27B0}\u{2934}-\u{2935}\u{2B05}-\u{2B07}\u{2B1B}-\u{2B1C}\u{2B50}\u{2B55}\u{3030}\u{303D}\u{3297}\u{3299}\u{1F004}\u{1F0CF}\u{1F170}-\u{1F171}\u{1F17E}-\u{1F17F}\u{1F18E}\u{1F191}-\u{1F19A}\u{1F1E7}-\u{1F1EC}\u{1F1EE}-\u{1F1F0}\u{1F1F3}\u{1F1F5}\u{1F1F7}-\u{1F1FA}\u{1F201}-\u{1F202}\u{1F21A}\u{1F22F}\u{1F232}-\u{1F23A}\u{1F250}-\u{1F251}\u{1F300}-\u{1F320}\u{1F330}-\u{1F335}\u{1F337}-\u{1F37C}\u{1F380}-\u{1F393}\u{1F3A0}-\u{1F3C4}\u{1F3C6}-\u{1F3CA}\u{1F3E0}-\u{1F3F0}\u{1F400}-\u{1F43E}\u{1F440}\u{1F442}-\u{1F4F7}\u{1F4F9}-\u{1F4FC}\u{1F500}-\u{1F507}\u{1F509}-\u{1F53D}\u{1F550}-\u{1F567}\u{1F5FB}-\u{1F640}\u{1F645}-\u{1F64F}\u{1F680}-\u{1F68A}\*$!@#$%^&*~()_+\=\[\]{};:"«»„”’‘\\|,.<>\/?\d]+/gi;

  //     this.$set(this, 'name', newVal.replace(re, ''));
  //   },
  //   company(newVal) {
  //     let re = /[\u{203C}\u{2049}\u{20E3}\u{2122}\u{2139}\u{2194}-\u{2199}\u{21A9}-\u{21AA}\u{231A}-\u{231B}\u{23E9}-\u{23EC}\u{23F0}\u{23F3}\u{24C2}\u{25AA}-\u{25AB}\u{25B6}\u{25C0}\u{25FB}-\u{25FE}\u{2600}-\u{2601}\u{260E}\u{2611}\u{2614}-\u{2615}\u{261D}\u{263A}\u{2648}-\u{2653}\u{2660}\u{2663}\u{2665}-\u{2666}\u{2668}\u{267B}\u{267F}\u{2693}\u{26A0}-\u{26A1}\u{26AA}-\u{26AB}\u{26BD}-\u{26BE}\u{26C4}-\u{26C5}\u{26CE}\u{26D4}\u{26EA}\u{26F2}-\u{26F3}\u{26F5}\u{26FA}\u{26FD}\u{2702}\u{2705}\u{2708}-\u{270C}\u{270F}\u{2712}\u{2714}\u{2716}\u{2728}\u{2733}-\u{2734}\u{2744}\u{2747}\u{274C}\u{274E}\u{2753}-\u{2755}\u{2757}\u{2764}\u{2795}-\u{2797}\u{27A1}\u{27B0}\u{2934}-\u{2935}\u{2B05}-\u{2B07}\u{2B1B}-\u{2B1C}\u{2B50}\u{2B55}\u{3030}\u{303D}\u{3297}\u{3299}\u{1F004}\u{1F0CF}\u{1F170}-\u{1F171}\u{1F17E}-\u{1F17F}\u{1F18E}\u{1F191}-\u{1F19A}\u{1F1E7}-\u{1F1EC}\u{1F1EE}-\u{1F1F0}\u{1F1F3}\u{1F1F5}\u{1F1F7}-\u{1F1FA}\u{1F201}-\u{1F202}\u{1F21A}\u{1F22F}\u{1F232}-\u{1F23A}\u{1F250}-\u{1F251}\u{1F300}-\u{1F320}\u{1F330}-\u{1F335}\u{1F337}-\u{1F37C}\u{1F380}-\u{1F393}\u{1F3A0}-\u{1F3C4}\u{1F3C6}-\u{1F3CA}\u{1F3E0}-\u{1F3F0}\u{1F400}-\u{1F43E}\u{1F440}\u{1F442}-\u{1F4F7}\u{1F4F9}-\u{1F4FC}\u{1F500}-\u{1F507}\u{1F509}-\u{1F53D}\u{1F550}-\u{1F567}\u{1F5FB}-\u{1F640}\u{1F645}-\u{1F64F}\u{1F680}-\u{1F68A}\!@#$%^&*~()_+\=\[\]{};:\\|,.<>\/?]+/gi;

  //     this.$set(this, 'company', newVal.replace(re, ''));
  //   },
  //   email(newVal) {
  //     let re = /[\u{203C}\u{2049}\u{20E3}\u{2122}\u{2139}\u{2194}-\u{2199}\u{21A9}-\u{21AA}\u{231A}-\u{231B}\u{23E9}-\u{23EC}\u{23F0}\u{23F3}\u{24C2}\u{25AA}-\u{25AB}\u{25B6}\u{25C0}\u{25FB}-\u{25FE}\u{2600}-\u{2601}\u{260E}\u{2611}\u{2614}-\u{2615}\u{261D}\u{263A}\u{2648}-\u{2653}\u{2660}\u{2663}\u{2665}-\u{2666}\u{2668}\u{267B}\u{267F}\u{2693}\u{26A0}-\u{26A1}\u{26AA}-\u{26AB}\u{26BD}-\u{26BE}\u{26C4}-\u{26C5}\u{26CE}\u{26D4}\u{26EA}\u{26F2}-\u{26F3}\u{26F5}\u{26FA}\u{26FD}\u{2702}\u{2705}\u{2708}-\u{270C}\u{270F}\u{2712}\u{2714}\u{2716}\u{2728}\u{2733}-\u{2734}\u{2744}\u{2747}\u{274C}\u{274E}\u{2753}-\u{2755}\u{2757}\u{2764}\u{2795}-\u{2797}\u{27A1}\u{27B0}\u{2934}-\u{2935}\u{2B05}-\u{2B07}\u{2B1B}-\u{2B1C}\u{2B50}\u{2B55}\u{3030}\u{303D}\u{3297}\u{3299}\u{1F004}\u{1F0CF}\u{1F170}-\u{1F171}\u{1F17E}-\u{1F17F}\u{1F18E}\u{1F191}-\u{1F19A}\u{1F1E7}-\u{1F1EC}\u{1F1EE}-\u{1F1F0}\u{1F1F3}\u{1F1F5}\u{1F1F7}-\u{1F1FA}\u{1F201}-\u{1F202}\u{1F21A}\u{1F22F}\u{1F232}-\u{1F23A}\u{1F250}-\u{1F251}\u{1F300}-\u{1F320}\u{1F330}-\u{1F335}\u{1F337}-\u{1F37C}\u{1F380}-\u{1F393}\u{1F3A0}-\u{1F3C4}\u{1F3C6}-\u{1F3CA}\u{1F3E0}-\u{1F3F0}\u{1F400}-\u{1F43E}\u{1F440}\u{1F442}-\u{1F4F7}\u{1F4F9}-\u{1F4FC}\u{1F500}-\u{1F507}\u{1F509}-\u{1F53D}\u{1F550}-\u{1F567}\u{1F5FB}-\u{1F640}\u{1F645}-\u{1F64F}\u{1F680}-\u{1F68A}\`"'!@#$%^&*~()«»„”’‘+\=\[\]{};:\\|,<>\/?а-яё]+/gi;

  //     this.$set(this, 'email', newVal.replace(re, ''));
  //   },
  // },
  computed: {
    ...mapGetters([
      'getOpenMenu',
      'getCurrentLangs'
    ]),
  },
  methods: {
    ...mapMutations([
      'setOpenMenu',
    ]),
    async handleSubmit() {
      if (!this.name) {
        this.handleError('name', this.$t('contact.name.error.empty'))
      }
      if (!this.email) {
        this.handleError('email', this.$t('contact.email.error.empty'))
      }
      if (!this.phone) {
        this.handleError('phone', this.$t('contact.phone.error.empty'))
      }
      if (!this.isAgree) {
        this.handleError('agree', 'error')
      }

      if (
        this.name
        && this.email
        && this.phone
        && !this.errors.name
        && !this.errors.email
        && !this.errors.phone
        && !this.errors.agree
      ) {
        const data = {
          name: this.name,
          magic: this.magic,
          company_type: this.work,
          company: this.company,
          email: this.email,
          number: this.phone,
        }
        await this.sendMail(data);
        this.isFormSent = true;
        this.animateTitle();
      }
    },
    handleNameValidation() {
      // const {value} = event.target;

      if (this.name) {
        const regEm = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
        this.name = this.name.replace(regEm, '');

        const regSymb = /[0-9*#$!~₴&_;:><.%^+\\@+)(=}{"?/"})({]|[\[\]]|[\\][^\w.,\s]+/g;
        this.name = this.name.replace(regSymb, '');
        if (this.name.replace(/\s/g, '').length > 50) {
          this.handleError('name', this.$t('contact.name.error.max'));
        } else {
          this.handleError('name', null)
        }
      }
    },
    handleCompanyValidation() {

      if (this.company) {
        const regEm = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
        this.company = this.company.replace(regEm, '');

        const regSymb = /[*}~₴`{,/|;:><.%+@!№#$^)(&_=]|[\[\]]|[\\][^\w.,\s]+/g;
        this.company = this.company.replace(regSymb, '');

        if (this.company.replace(/\s/g, '').length > 20) {
          this.handleError('company', this.$t('contact.company.error.max'));
        } else {
          this.handleError('company', null)
        }
      }
    },
    handleEmailValidation() {
      // const {value} = event.target;
      if (this.email) {
        this.email = this.email.replace(/[аА-яЯіїє*;:><%+!#$^)(&_=}{"'?\\/]|[\[\]]/g, "");

        if (!(/^(([^<>()[\]\\.%'"$&`><^ \s@"]+(\.[^<>()[\]\\.%\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email))) {
          this.handleError('email', this.$t('contact.email.error.example'));
        } else {
          this.handleError('email', null)
        }
      }
    },
    handleError(error, value) {
      this.errors[error] = value;
    },

    async sendMail(data) {

      var mailData = JSON.stringify({
        MailId: 25,
        Data: JSON.stringify({"data": data})
      });
      var res = fetch('https://connector-stage.azurewebsites.net/api/mail/post', {
        method: 'POST',
        body: mailData,
        headers: {
          "Content-Type": "application/json"
        }
      })

      return res;
    },

    handleAgree() {
      this.isAgree = !this.isAgree;
      this.handleError('agree', null)
    },
    selectMagic(item) {
      this.isMagicListHidden = true;
      this.magic = item;
    },
    selectWork(item) {
      this.isWorkListHidden = true;
      this.work = item;
    },
    reload() {
      window.location.reload();
    },
    animateTitle() {
      setTimeout(() => {
        const contact = document.querySelector('#contact');
        const textWrapperAll = contact.querySelectorAll('.text-wrapper .letters');
        textWrapperAll.forEach((e, index) => {
          const textWrapper = e;

          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter strong'>$&</span>");


          anime.timeline({
            loop: false,
          }).add({
            targets: '.text-wrapper .letter',
            translateY: ['1.2em', 0],
            translateZ: 0,
            duration: 1000,
            delay: (el, i) => i * 50,
          });
        });
      }, 20);
    }
  },
  mounted() {
    this.animateTitle();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/animation.scss";

.contact {
  display: flex;
  align-items: center;
  min-height: 100vh;
  text-align: left;
  color: var(--text-primary-color);

  &__address-text {
    display: inline;
    text-decoration: none;
    background: linear-gradient(to right, rgba(100, 200, 200, 0), rgba(100, 200, 200, 0)),
    linear-gradient(to right, rgba(146, 161, 174, 1), rgba(146, 161, 174, 1));
    background-size: 100% 0.1em, 0 0.1em;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;

    @media screen and (min-width: 1023px) {
      &:hover {
        background-size: 0 1px, 100% 1px;
      }
    }

    &:active {
      background-size: 0 1px, 100% 1px;
    }
  }

  path {
    stroke: var(--text-primary-color);
  }

  .desk {
    @media (max-width: 1029px) {
      display: none;
    }

    &-tablet {
      @media screen and (max-width: 660px) {
        display: none;
      }
    }
  }

  .mob {
    display: none;

    @media (max-width: 1029px) {
      display: block;
    }

    &-second {
      display: none;
      @media screen and (max-width: 660px) {
        display: block;
      }
    }
  }

  &__content {
    padding: calc(49px + 88px) 15px 49px;
    margin: 0 auto;
    max-width: 1141px;

    @media (max-width: 768px) {
      padding: calc(49px + 68px) 45px 49px;
    }

    @media (max-width: 500px) {
      padding: calc(43px + 53px) 20px 43px;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    color: var(--text-primary-color);
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.04em;

    &:hover,
    &:focus {
      & path {
        stroke: #0BE9C9;
      }
    }
  }

  &__required {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #29F6D9;
  }

  &__main {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 30px;
    margin-top: 23px;

    @media (max-width: 1029px) {
      grid-template-columns: 1fr;
    }
  }

  &__info {
    @media (max-width: 1029px) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__title {
    font-family: 'Times New Roman';
    font-style: italic;
    font-weight: 400;
    font-size: 108px;
    line-height: 94px;
    margin-bottom: 21px;
    position: relative;

    &.thanks {
      line-height: 119px;

      .text-wrapper {
        padding-left: 0.11em;
      }
    }

    .text-wrapper {
      position: relative;
      display: inline-block;
      overflow: hidden;

      &:nth-of-type(2) {
        padding-top: 0;
      }
    }

    .letter {
      display: inline-block;

      &.strong {
        font-family: "TimesNewRoman-I";
        font-style: italic;
        color: var(--footer-text-color);
      }
    }

    @media (max-width: 768px) {
      font-size: 66px;
      line-height: 64px;
    }

    @media (max-width: 500px) {
      font-size: 44px;
      line-height: 44px;
    }
  }

  &__address {
    &-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #92A1AE;
      margin-bottom: 13px;
    }

    &-text {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #92A1AE;
    }

    &.desk-tablet {
      @media screen and (max-width: 1029px) {
        margin-left: 72px;
      }
    }
  }

  &__field {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 34px;

    &--phone {
      padding-bottom: 0;
    }
  }

  &__double-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 31px;

    &--phone {
      @media (max-width: 1100px) {
        grid-template-columns: 1fr;
      }

      .contact__error {
        white-space: nowrap;
      }
    }

    & > .company-name {
      position: relative;

      .contact__error {
        bottom: -24px;
      }
    }

    @media (max-width: 1100px) {
      grid-template-columns: 1fr;
    }
  }

  &__label {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

    @media (max-width: 500px) {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
    }

    sup {
      color: #0BE9C9;
      font-size: 1em;
      transform: translateY(5px);

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  &__input {
    padding: 14px 0 12px;
    border: none;
    border-bottom: 1px solid var(--text-primary-color);
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    width: 100%;
    height: 100%;
    background-color: var(--background-color-primary);
    color: var(--text-primary-color);

    &[type="text"] {
      padding-right: 46px;
    }

    @media (max-width: 500px) {
      padding: 4px 0;
      font-size: 20px;
      line-height: 28px;
    }

    &::placeholder {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      color: #92A1AE;

      @media (max-width: 500px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    &:focus {
      border-color: #29F6D9;
    }

    &.error {
      border-color: #ED475D;
    }
  }

  &__select {
    position: relative;
    background: var();
    height: 100%;

    &-shown {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      padding: 14px 0 12px;
      border-bottom: 1px solid var(--text-primary-color);
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;

      @media (max-width: 500px) {
        padding: 4px 0;
        font-size: 20px;
        line-height: 28px;
      }

      svg {
        transition: 0.4s;
      }

      &.active svg {
        transform: rotate(180deg);
      }
    }

    &-placeholder {
      color: #92A1AE;

      &.active {
        color: var(--text-primary-color);
      }
    }

    &-list {
      position: absolute;
      left: 0;
      top: calc(100% - 2px);
      z-index: 1;
      height: auto;
      max-height: 100vh;
      width: 100%;
      background-color: var(--background-color-primary);
      overflow: hidden;
      transition: 0.4s;
      border: 1px solid;

      @media (max-width: 500px) {
        top: 37px;
      }

      &.hidden {
        max-height: 0px;
        border: none;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 20px;
      border: 1px solid #D4DADF;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      transition: 0.4s;

      @media (max-width: 500px) {
        padding: 16px 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      &.active {
        background-color: #29F6D9;

        p {
          color: #000;
        }

        svg {
          min-width: 20px;
          max-width: 20px;
        }
      }

      &:hover {
        background-color: #29F6D9;
      }

      path {
        stroke: #000;
      }
    }
  }

  &__checkbox {
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    @media (max-width: 500px) {
      align-items: flex-start;
    }

    &.error {
      color: #ED475D;
    }

    p {
      border-bottom: 1px solid #29F6D9
    }

    input {
      display: none;
    }

    sup {
      color: #0BE9C9;
      font-size: 1em;
      transform: translate(3px, -3px);
    }

    &-custom {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 8px;
      border: 1px solid var(--text-primary-color);
      box-sizing: border-box;
      border-radius: 2px;

      svg path {
        stroke: var(--background-color-primary);
      }

      &.active {
        border: 1px solid #0BE9C9;
        background: #29F6D9;
      }
    }
  }

  &__max {
    position: absolute;
    right: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #92A1AE;

    &.first {
      bottom: 5px;
    }

    &.second {
      bottom: -50%;
    }
  }

  &__error {
    position: absolute;
    left: 0;
    bottom: 10px;
    font-weight: 100;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #ED475D;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__button {
    position: relative;
    width: fit-content;
    font-family: 'Times New Roman';
    font-style: italic;
    font-weight: 400;
    font-size: 66px;
    line-height: 64px;
    transition: 0.4s;

    @media (max-width: 500px) {
      font-weight: 400;
      font-size: 44px;
      line-height: 44px;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: var(--text-primary-color);
      transition: 0.4s;
    }

    &:hover {
      &::before {
        width: 10%;
      }
    }

    &:focus,
    &:active {
      color: #0BE9C9;

      &::before {
        width: 10%;
        background: #0BE9C9;
      }
    }

    &.disabled {
      color: #D4DADF;

      &::before {
        background: #D4DADF;
      }

      &:focus,
      &:active {
        color: #D4DADF;

        &::before {
          width: 10%;
          background: #D4DADF;
        }
      }
    }
  }

  &__result {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    margin: 0 auto;

    @media (max-width: 500px) {
      padding: 0 20px;
      grid-template-columns: 1fr;
      text-align: center;
      margin-top: 58px;
    }

    svg {
      max-width: 100%;
      height: auto;
    }

    .contact__text {
      margin-top: 24px;
      margin-bottom: 75px;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
    }

    .contact__button-res {
      position: relative;
      z-index: 10;
      display: flex;
      align-items: center;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.04em;

      @media (max-width: 500px) {
        margin: 0 auto;
      }

      & svg {
        stroke: #000;
        margin-right: 15px;
      }

      &:hover,
      &:focus {
        & path {
          stroke: #0BE9C9;
        }
      }
    }
  }

  &__gradients {
    position: fixed;
    top: -40%;
    left: -15%;
    max-width: 1000px;
    object-fit: contain;
    pointer-events: none;
    z-index: 0;
    animation: rotateGradient1 20s infinite linear;

    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    user-select: none;
  }
}
</style>
